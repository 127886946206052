import { useEffect, useState } from "react"
import "./popup.css"

const Popup = () => {
	const [visible, setVisible] = useState(false)

	const popupLocal = localStorage.getItem("popupReverseChristmas")

	useEffect(() => {
		if (!popupLocal) setVisible(true)
		if (popupLocal) setVisible(false)
	}, [popupLocal])

	const deleteLocalStorage = () => {
		localStorage.removeItem("popup")
		localStorage.setItem("popupReverse", false)
		setVisible(false)
	}

	return (
		<>
			<div className={`${visible ? "background-hidden" : ""}`}></div>
			<div className={`${visible ? "block popup" : "hidden"}`}>
				<p className="title">Zastrzeżenie twojego serwera</p>
				<p className="description">
					Shizeclone.eu to kloner discord który może sklonować każdy serwer discord, nawet te serwery do których nie
					mamy permisji. Co za tym idzie ktoś może sklonować twój serwer i go udostępnić. Wprowadzamy opcje gdzie za
					drobną opłatą będziecie mogli zastrzec swój serwer discord, wam nikt serwera nie sklonuje a nas to wspomoże:].
					Oto co otrzymujesz:
				</p>
				<div className="ofert-list">
					<div className="section-bonus">
						<p className="title-bonus">Blokada klonowania</p>
						<p className="description-bonus">
							Nikt nie będzie mógł sklonować twojego serwera, nie będzie mógł sklonować emoji ani RolesEmoji. Czyli
							blokada tyczy sie wszystkich klonerów
						</p>
					</div>
					<div className="section-bonus">
						<p className="title-bonus">Twój szablon nie zostanie udostepniony</p>
						<p className="description-bonus">
							Każdy sklonowany serwer jest udostępniany na https://szablonydiscord.pl/, gdzie każdy może wziąć templatke
							twojego serwera. Po zakupie subskrybcji twoj serwer tam sie nie pojawi.
						</p>
					</div>
					<div className="section-bonus">
						<p className="title-bonus">Cena subskrybcji (PROMOCJA ŚWIĄTECZNA!)</p>
						<p className="description-bonus">
							Za te wszystkie opisane rzeczy zapłacisz{" "}
							<span className="bold ">
								jedyne <span className="line">25.00</span> 17.50 zł/na zawsze!
							</span>{" "}
							Dzięki temu wspierasz działalność shizeclone.eu który jest całkowicie bezpłatny. Dzięki twojej subskrypcji
							jesteśmy w stanie się dalej rozwijać. (Więcej za co płacisz na naszym discordzie)
						</p>
					</div>
				</div>
				<div className="buttons">
					<a href="https://discord.gg/M5eVcMdWRd" className="button-template">
						Przejdź do zakupu
					</a>
					<button className="button-template" onClick={deleteLocalStorage}>
						Anuluj
					</button>
				</div>
			</div>
		</>
	)
}

export default Popup
